import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SESSION_FOR_LIVE_VIDEO_FRAGMENT = `
  fragment sessionForLiveVideoFragment on Session {
    uid
    id
    schemaCode
    name
    shortName
    description
    descriptionHtml
    startTime
    startTimestamp
    endTime
    endTimestamp
    speakers
    featured
    surveyUrl
    showLiveChat
    showQNA
    showPolls
    videoLiveEndpoint
    videoLiveEmbedEndpoint
    videoLiveEnabled
    videoLiveRTMPEndpoint
    videoLiveRTMPKey
    videoArchiveEndpoint
    videoArchiveEmbedEndpoint
    videoArchiveEnabled
    videoType
    videoEnabled
    videoEnabledStartTime
    videoEnabledStartTimestamp
    videoEnabledEndTime
    videoEnabledEndTimestamp
    parentSession {
      uid
    }
    moderators: _moderators {
      uid
      firstName
      lastName
      pictureFileResource {
        schemaCode
        path
      }
    }
    likeCount: _likeCount
    dislikeCount: _dislikeCount
    loveCount: _loveCount
    imageFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _actions(actions: ["CAN_VIEW_LIVE_VIDEO"]) {
       value
       key
    }
    _isInAgenda(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
